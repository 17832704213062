<template>
  <validation-observer ref="hardwareForm" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit()" autocomplete="off" novalidate>
      <b-container>
        <b-row>
          <b-col>
            <validation-provider name="nombre" rules="required" v-slot="validationContext">
              <b-form-group id="hardware-name" label-for="input-hardware-name">
                <h6>Nombre</h6>
                <b-form-input
                  v-model="form.name"
                  id="input-hardware-name"
                  name="input-hardware-name"
                  placeholder="Nombre del equipo"
                  maxlength="50"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider name="marca" rules="required" v-slot="validationContext">
              <b-form-group id="hardware-brand" label-for="input-hardware-brand">
                <ProfessionalSkillSelect
                  v-model="form.brand.id"
                  title="Marca"
                  placeholder="Escribe la marca y elige de la lista"
                  :options="hardwareBrandList"
                  :suggestions="true"
                  suggestionItem="marca"
                  :selectedOptions="selectedBrand"
                  eventName="removeRole"
                  @onRemoveRole="onDeleteHardwareBrand"
                  addEventName="addNew"
                  @addNew="onAddHardwareBrand"
                  :are-options-loaded="hardwareBrandList.length === 0"
                  suggestionText="Sugiere una nueva"
                  suggestionTitleText="No encuentras una"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider name="tipo de producto" rules="required" v-slot="validationContext">
              <b-form-group id="hardware-brand" label-for="input-hardware-brand">
                <ProfessionalSkillSelect
                  v-model="form.productType.id"
                  title="Tipo de equipo"
                  placeholder="Escribe el tipo de equipo y elige de la lista"
                  :options="productTypeList"
                  :suggestions="true"
                  suggestionItem="tipo de equipo"
                  :selectedOptions="selectedProductType"
                  eventName="removeRole"
                  @onRemoveRole="onDeleteHardwareProductType"
                  addEventName="addNew"
                  @addNew="onAddHardwareProductType"
                  :are-options-loaded="productTypeList.length === 0"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <validation-provider name="descripción" v-slot="validationContext">
              <b-form-group id="hardware-description" label-for="input-hardware-description">
                <h6>Descripción opcional</h6>
                <b-form-textarea
                  v-model="form.description"
                  id="input-hardware-description"
                  name="input-hardware-description"
                  maxlength="400"
                  placeholder="Escribe una descripción del equipo"
                  :state="getValidationState(validationContext)"
                ></b-form-textarea>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col>
            <b-form-group id="hardware-description" label-for="input-hardware-description">
              <h6>
                Visibilidad
                <b-icon-question-circle-fill
                  class="visible__icon"
                  id="visible"
                />
              </h6>
              <div>
                <b-form-checkbox
                  v-model="form.visible"
                  class="avify-checkbox filter"
                  name="deleteReason"
                  :unchecked-value="false"
                  :value="true"
                >
                  Visible
                </b-form-checkbox>
              </div>
            </b-form-group>
            <b-popover target="visible" :show.sync="popover">
              <div class="h4" style="text-align: right">
                <b-icon-x style="color: black" @click="popover = !popover" />
              </div>
              <p>
                Si marcas Visible, tu equipamiento será visible en tu perfil público
              </p>
            </b-popover>
          </b-col>
        </b-row> -->
      </b-container>
    </b-form>
  </validation-observer>
</template>

<script>
// import { BIconQuestionCircleFill, BIconX } from 'bootstrap-vue'
import ProfessionalSkillSelect from '../../../ProfessionalSkillSelect/ProfessionalSkillSelect.vue'

export default {
  name: 'HardwareForm',
  components: {
    // BIconQuestionCircleFill,
    // BIconX,
    ProfessionalSkillSelect
  },
  props: {
    form_prop: Object
  },
  data () {
    const selectedBrand = []
    const selectedProductType = []
    return {
      form: this.form_prop,
      hardwareBrandList: [],
      productTypeList: [],
      selectedBrand,
      selectedProductType,
      popover: false
    }
  },
  mounted () {
    this.$store.dispatch('getHardwareBrands')
      .then(res => {
        this.hardwareBrandList = res.map(hardwareBrand => {
          return { text: hardwareBrand.getName, value: hardwareBrand.getId }
        })
        if (this.form_prop.brand.id) {
          const brand = res.find(brand => {
            return brand.getId === this.form_prop.brand.id
          })
          this.selectedBrand.push(brand)
        }
      })

    this.$store.dispatch('getProductTypes')
      .then(res => {
        this.productTypeList = res.map(productType => {
          return { text: productType.getName, value: productType.getId }
        })
        if (this.form_prop.productType.id) {
          const productType = res.find(productType => {
            return productType.getId === this.form_prop.productType.id
          })
          this.selectedProductType.push(productType)
        }
      })
  },
  methods: {
    onAddHardwareBrand (brand) {
      this.selectedBrand = [brand]
      this.form.brand.id = brand.id
    },
    onAddHardwareProductType (productType) {
      this.selectedProductType = [productType]
      this.form.productType.id = productType.id
    },
    onRemoveHardware () {
      this.$emit('removed')
    },
    onDeleteHardwareBrand () {
      this.selectedBrand = []
      this.form.brand.id = ''
    },
    onDeleteHardwareProductType () {
      this.selectedProductType = []
      this.form.productType.id = ''
    }
  }
}
</script>

<style lang="less" scoped>
@import "hardwareForm";
</style>

<template>
  <div class="ProfessionalSkillSelect">
    <div class="ProfessionalSkillSelect-title" v-if="showHeader">
      <h6>{{ title }}</h6>
      <small v-if="suggestions">
        ¿{{ suggestionTitleText }} {{ suggestionItem }}?
        <strong @click="onAddSuggestion">
          {{ suggestionText }} {{ suggestionItem }}
        </strong>
      </small>
    </div>
    <list-select
      :list="options"
      option-value="value"
      option-text="text"
      v-model="item"
      :placeholder="placeholder"
      :filterPredicate="cleanSearch"
      @select="onSelect"
      :isDisabled="areOptionsLoaded"
      :state="state"
    >
    </list-select>
    <div ref="badgeContainer" class="ProfessionalSkillSelect-list mt-2">
      <SkillBadge
        :name="selectedOption.name"
        :id="selectedOption.id"
        :eventName="eventName"
        @removeSector="onRemoveSector"
        @removeRole="onRemoveRole"
        @removeSkill="onRemoveSkill"
        @removeDrivingLicense="onRemoveDrivingLicense"
        :dismisable="true"
        :key="selectedOption.id"
        v-for="selectedOption in selectedOptions"
      />
    </div>
    <AddSuggestionModal
      :id="addSuggestionModalId"
      :type="suggestionItem"
      :suggestionText="suggestionText"
    >
    </AddSuggestionModal>
  </div>
</template>

<script>

import { ListSelect } from 'vue-search-select'
import SkillBadge from '@/components/SkillBadge/SkillBadge'
import AddSuggestionModal from '../Modal/AddSuggestionModal'

export default {
  props: {
    showHeader: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    suggestions: {
      type: Boolean,
      required: false,
      default: false
    },
    suggestionItem: {
      type: String,
      required: false,
      default: ''
    },
    selectedOptions: {
      type: Array,
      required: false,
      default: () => []
    },
    eventName: {
      type: String,
      required: false
    },
    addEventName: {
      type: String,
      required: false
    },
    areOptionsLoaded: {
      type: Boolean,
      required: false,
      default: false
    },
    state: {
      type: Boolean,
      default: true
    },
    suggestionText: {
      type: String,
      default: 'Sugiere un nuevo'
    },
    suggestionTitleText: {
      type: String,
      default: 'No encuentras un'
    }
  },
  watch: {
    state: function () {
      this.$el.classList.remove('is-invalid')
      this.$el.querySelector('input.search').removeAttribute('style')
      if (!this.state) {
        this.$el.classList.add('is-invalid')
        this.$el.querySelector('input.search').setAttribute('style', 'border: 1px solid #dc3545 !important')
      }
    }
  },
  components: {
    ListSelect,
    SkillBadge,
    AddSuggestionModal
  },
  data () {
    return {
      item: {
        value: '',
        text: ''
      }
    }
  },
  computed: {
    addSuggestionModalId () {
      return 'add-suggestion-' + this.suggestionItem
    }
  },
  methods: {
    onAddSuggestion () {
      this.$bvModal.show(this.addSuggestionModalId)
    },
    onSelect (item) {
      if (Object.entries(item).length === 0) {
        return
      }

      const skill = {
        id: item.value,
        name: item.text
      }
      this.$emit(this.addEventName, skill)
    },
    onRemoveSector (sectorId) {
      this.$emit('onRemoveSector', sectorId)
    },
    onRemoveRole (roleId) {
      this.$emit('onRemoveRole', roleId)
    },
    onRemoveSkill (skillId) {
      this.$emit('onRemoveSkill', skillId)
    },
    onRemoveDrivingLicense (drivingLicenseId) {
      this.$emit('onRemoveDrivingLicense', drivingLicenseId)
    },
    cleanSearch (text, inputText) {
      const cleanText = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
      const cleanInput = inputText.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
      return cleanText.includes(cleanInput)
    }
  }
}
</script>

<style lang="less">
  @import 'professionalSkillSelect.less';
</style>

<template>
  <b-modal
    :id="id"
    modal-class="user-information-modal"
    centered
    size="sm"
    hide-header
    hide-footer
  >
    <h5 class="text-center">{{ suggestionText }} {{ type }}</h5>
    <validation-observer ref="updateCustomUrl" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(onAddSuggestion)" autocomplete="off" novalidate>
        <validation-provider name="sugerencia" rules="required" v-slot="validationContext">
          <b-form-group id="suggestion" label-for="suggestionName">
            <b-form-input
              id="suggestionName"
              name="suggestion-name"
              type="text"
              v-model="suggestion"
              :state="getValidationState(validationContext)"
            ></b-form-input>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <div class="text-center">
          <b-button block type="submit" variant="primary" ref="submit-button">
            Enviar sugerencia
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
export default {
  name: 'AddSuggestionModal',
  props: {
    type: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    suggestionText: {
      type: String,
      default: 'Sugiere un nuevo'
    }
  },
  data () {
    return {
      suggestion: ''
    }
  },
  methods: {
    onAddSuggestion () {
      const suggestion = { type: this.type, name: this.suggestion }
      this.$refs['submit-button'].innerHTML = 'Enviando...'
      this.$store.dispatch('sendSuggestion', suggestion)
        .then(() => { this.$bvModal.hide(this.id) })
    }
  }
}
</script>

<style lang="less">
  @import "userInformationModal.less";
</style>
